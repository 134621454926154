<template>
<div class="toolTipBox">
    <div class="giveLive flex0" v-show="Show">
    <div class="invite-friends-content tc">
      <i v-if="Type==1" class="el-icon-circle-close close-alert co-fff cur" @click="closeAlert(Type)"></i>
      <h1 class="invite-title tc">您还没有登录,<br />{{Content}}</h1>
      <div class="live_but flex8">
        <div class="live_but_l cur" @click="GoLogin">
          已有剑盟账号，前往登录
        </div>
        <div class="live_but_l cur" @click="GoRegister">没有账号，立即注册</div>
      </div>
    </div>
    </div>
</div>
</template>

<script>

export default {
  name: "toolTipBox",
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    closeAlert(type) {
      if(type == 1){
          this.$emit('liveChange',false )
      } else if(type ==2){
        this.$emit('postChange',false)
      }
    },
    GoLogin(){
      this.$router.push('/Login?redirectUrl=' + this.$route.fullPath  + '&from=live')
    },
    GoRegister(){
      this.$router.push('/Register?redirectUrl=' + this.$route.fullPath  + '&from=live')
    },
  },
  props: {
    Show: {
      type: Boolean
    },
    Content:{
      type:String
    },
    Type:{
      type:Number
    }
  },
};
</script>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
  .toolTipBox{
  .giveLive{width: 100%; height: 102vh; position: fixed;top: 0;left: 0; background: rgba(0, 0, 0, 0.6);z-index: 999999;
  .live_but{width: 100%; padding: 50px 25px 40px 25px;}
  .live_but_l{padding: 6px 15px; background: #6B04A8; color: #fff; font-size: 16px; }
  .live_but_l:hover{opacity: 0.7;}
  .back{width: 500px;}
  .invite-friends-content { width: 500px; background: url("~@/assets/PC/InnovationContest/invite-friends.png") no-repeat; background-size: 100% 100%; padding-top: 90px }
  .invite-code-box { width: 150px; height: 150px; margin: 0 auto; border: 10px #E4E4E4 solid; border-radius: 5px }
  .invite-title { font-size: 24px; font-weight: bold; margin: 20px 0 }
  .invite-introduce { font-size: 16px; font-weight: bold; margin-bottom: 50px }
  .invite-introduce b { font-size: 16px }
  }
  }

@media (max-width: 750px) {
  .toolTipBox{
  .giveLive{width: 100%; height: 102vh; position: fixed;top: 0;left: 0; background: rgba(0, 0, 0, 0.6);z-index: 999999;
  .invite-friends-content{width: vw(700); position: relative; padding-top: vw(95);}
  .close-alert {position: absolute; top: vw(24); right: vw(24); font-size: vw(48);background: transparent;} 
  .invite-title{font-size: vw(44);}
  .close_{top:-5px;right: -5px;}
  .back{width: vw(700);}
  .live_but{ width: 100%; top:vw(330); padding: vw(50) vw(30) vw(60) vw(30);}
  .live_but_l{padding: 5px 8px;  font-size: vw(26); }
  h1{text-align: center;}
  }
  }
}
</style>